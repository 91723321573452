import React from "react";
// import { FaArrowDown } from "react-icons/fa";
import logo from "../images/logo-white.svg";
export default function FooterSection({ refs }) {
	return (
		<div className="section5" ref={refs}>
			<div className="semi">
				{/* <div className="button">
					<span> Download Now</span>
					<span>
						<FaArrowDown />
					</span>
				</div> */}
				<div className="button">
					<a
						href="https://app.entrepedia.in"
						target="_blank"
						rel="noopener noreferrer">
						<span> Try Our App</span>
						<span>
							<img src={logo} alt="" />
						</span>
					</a>
				</div>
			</div>

			<div className="text">We Would Love To Hear From You !</div>
			<div className="input-container">
				<span className="input">
					<input type="text" placeholder="Enter your email..." />
				</span>
				<span className="btn-container">
					<button>Get in Touch</button>
				</span>
			</div>
			<div className="copyright">2020 Entrepedia. All rights reserved</div>
		</div>
	);
}
