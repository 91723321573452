const ScrollIntoViewOrShake = (ref, type) => {
	if (ref.current) {
		if (type === "shake") {
			ref.current.classList.add("shake_error-fn");
			window.navigator.vibrate(200);
			setTimeout(() => {
				if (ref.current !== null) {
					ref.current.classList.remove("shake_error-fn");
				}
			}, 500);
		} else if (type === "scroll") {
			ref.current.scrollIntoView({
				behavior: "smooth",
				block: "start",
			});
		} else {
			ref.current.classList.add("shake_error-fn");
			window.navigator.vibrate(200);
			setTimeout(() => {
				if (ref.current !== null) {
					ref.current.classList.remove("shake_error-fn");
				}
			}, 500);
			ref.current.scrollIntoView({
				behavior: "smooth",
				block: "start",
			});
		}
	}
};
export default ScrollIntoViewOrShake;
