import { useEffect, useState } from "react";

export const useScrollPosition = () => {
	const [prevScrollPos, setPrevScrollPos] = useState(window.pageYOffset);
	const [visible, setVisible] = useState(true);

	useEffect(() => {
		const handleScroll = () => {
			const currentScrollPos = window.pageYOffset;
			const visible = prevScrollPos > currentScrollPos;
			if (window.pageYOffset > 100) {
				if (prevScrollPos !== currentScrollPos) {
					setPrevScrollPos(currentScrollPos);
					setVisible(visible);
				}
			}
		};
		window.addEventListener("scroll", handleScroll);
		return () => {
			window.removeEventListener("scroll", handleScroll);
		};
	}, [prevScrollPos]);
	return visible;
};
