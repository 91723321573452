import React from "react";
// import { MdOndemandVideo } from "react-icons/md";
import HowItWorkModal from "../components/HowItWorkModal";
// import hiw from "../images/hiw.svg";

export default function HowItWorkSection({
	isModalOpen,
	setIsModalOpen,
	refs,
}) {
	return (
		<div ref={refs} className="section2">
			<div className="doodle"></div>
			<div className="hiw">
				{/* <div className="illustrator">
					<img src={hiw} alt="" />
				</div> */}
				<div className="content">
					<ul className="steps">
						<li>Download the Entrepedia app or login to the website</li>
						<li>
							Build a company by answering basic questions about your business
							idea
						</li>
						<li>
							There are video lectures to support each section and help you
							learn
						</li>
						<li>Make a profit for your business as soon as possible</li>
						<li>
							Share your accomplishments with teachers, friends and family
						</li>
					</ul>
					{/* <div>
                        It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English.
                        </div> */}
					{/* <div onClick={() => setIsModalOpen(true)}>
						<span>Watch Demo </span>
						<span>
							<MdOndemandVideo />
						</span>
					</div> */}
				</div>
			</div>
			{isModalOpen ? (
				<HowItWorkModal
					isModalOpen={isModalOpen}
					setIsModalOpen={setIsModalOpen}
				/>
			) : null}
		</div>
	);
}
