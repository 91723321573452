import React from "react";
import ReactPlayer from "react-player";
import Modal from "../components/Modal";

export default function HowItWorkModal({ isModalOpen, setIsModalOpen }) {
	return (
		<Modal
			open={isModalOpen}
			title="Get Started"
			close={() => setIsModalOpen(false)}>
			<div className="video">
				<ReactPlayer url="https://www.youtube.com/watch?v=O_n9_Uz34v0&list=PLZv8x7uxq5Xb6FWZfU6f3kjeasNBsUbxq" />
			</div>
		</Modal>
	);
}
