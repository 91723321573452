import React from "react";

export default function Card({ img, clsName, text, desc }) {
	const renderColor = () => {
		let style;
		if (clsName === "purple") {
			style = {
				background: "#6900ff",
				boxShadow: "0px 20px 45px rgba(105, 0, 255, 0.2)",
			};
		} else if (clsName === "green") {
			style = {
				background: "#5cd786",
				boxShadow: "0px 20px 45px rgba(92, 215, 134, 0.2)",
			};
		} else if (clsName === "yellow") {
			style = {
				background: "#ffaa00",
				boxShadow: "0px 20px 45px rgba(255, 170, 0, 0.2)",
			};
		} else {
			return null;
		}

		return style;
	};
	return (
		<div className="card">
			<div className="card-section1" style={renderColor()}>
				<div>{text}</div>
				<div>
					<img src={img} alt="" />
				</div>
			</div>
			<div className="card-section2">{desc}</div>
		</div>
	);
}
