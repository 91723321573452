import React from "react";
import share from "../images/share.svg";
import collaborate from "../images/collaborate.svg";
import bulb from "../images/bulb.svg";
import Card from "../components/Card";
import TitleComponent from "../components/TitleComponent";

export const featureDatas = [
	{
		title: "Build an Idea",
		classNames: "green",
		img: bulb,
		desc:
			"Go through the journey of converting an Idea into a business. Our gamified platform will push you to enter numbers and make it a fun journey for you to understand entrepreneurship.",
	},
	{
		title: "Collaborate with your friends",
		classNames: "purple",
		img: collaborate,
		desc:
			"Invite your friends to build the company with you. Together, you can play different roles and answer questions to make it a fun journey",
	},
	{
		title: "Share your success with the world",
		classNames: "yellow",
		img: share,
		desc:
			"Once you build a successful business, share your story with the world on Facebook, Instagram and WhatsApp. Let the world know your success story",
	},
];

export default function FeatureSection({ refs }) {
	return (
		<div ref={refs} className="section3">
			{/* remove the below img when testimonial is added */}
			{/* <img className="section4img" src={waves} alt="" /> */}
			<div className="main">
				<TitleComponent
					title="Our Features"
					para="The platform was built in such a way that the students as it's user will be able to reap various benefits such as improved critical thinking, problem solving etc. It helps in building strong collaborative skills and most of all in moulding their minds to develop the entrepreneurial way of thinking"
				/>

				<div className="feature-container">
					{featureDatas.map((data, i) => {
						return (
							<Card
								key={i}
								img={data.img}
								text={data.title}
								desc={data.desc}
								clsName={data.classNames}
							/>
						);
					})}
				</div>
			</div>
		</div>
	);
}
