import React, { Component } from "react";

class Modal extends Component {
    state = {
        props: this.props
    };
    container = React.createRef();

    componentDidMount () {
        if ( this.props.open ) {
            this.setState( { props: this.props } );
            document.body.style.overflow = "hidden";
        }
        document.addEventListener( "mousedown", this.handleClickOutside );
    }

    componentWillUnmount () {
        document.removeEventListener( "mousedown", this.handleClickOutside );
        document.body.style.overflow = "auto";
    }

    handleClickOutside = event => {
        if ( this.container.current && !this.container.current.contains( event.target ) ) {
            if ( !this.props.mandatory ) {
                this.onCloseModal( this.state.props );
            }
        }
    };

    onCloseModal ( props ) {
        props.close();
        document.body.style.overflow = "auto";
    }

    render () {
        return (
            <>
                {this.props.open ? (
                    <div className={this.props.className ? `modal ${this.props.className}` : `modal`}>
                        <div className="modal_content" ref={this.container}>
                            <div className="wrapper">
                                <div className={this.props.title ? "modal_head" : "modal_head trans"}>
                                    {this.props.title ? (
                                        <h4 className="heading-ttl">
                                            <span>{this.props.title}</span>
                                        </h4>
                                    ) : null}
                                    {!this.props.mandatory ? (
                                        <span className="close-x" onClick={() => this.onCloseModal( this.props )}>
                                            x
                                        </span>
                                    ) : null}
                                </div>
                                {this.props.children.length ? (
                                    <>
                                        {this.props.children[0] ? <>{this.props.children[0]}</> : null}
                                        {this.props.children[1] ? <>{this.props.children[1]}</> : null}
                                    </>
                                ) : (
                                        <>{this.props.children ? <>{this.props.children}</> : null}</>
                                    )}
                            </div>
                        </div>
                    </div>
                ) : null}
            </>
        );
    }
}

export default Modal;
