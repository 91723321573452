import React from "react";
import { IoMdLogIn } from "react-icons/io";
import scrollIntoView from "../common/scrollIntoView";

export default function NavBar({ visible, refs }) {
	console.log(refs);
	return (
		<div className={`nav-container ${visible ? "navbar--up" : "navbar--down"}`}>
			{/* <div className="navbar"></div> */}
			<div className="nav">
				<div className="nav-content">
					<div className="logo">
						<div></div>
					</div>
					<ul>
						<li onClick={() => scrollIntoView(refs[0], "scroll")}>Home</li>
						<li onClick={() => scrollIntoView(refs[1], "scroll")}>
							How does it work ?
						</li>
						<li onClick={() => scrollIntoView(refs[2], "scroll")}>Features</li>
						{/* <li>Learning</li> */}
						{/* <li>Testimonials</li> */}
						<li onClick={() => scrollIntoView(refs[3], "scroll")}>Contact</li>
						<li>
							<a href="https://app.entrepedia.in/" target="_blank">
								<span>Login</span>
								<span>
									<IoMdLogIn />
								</span>
							</a>
						</li>
					</ul>
				</div>
			</div>
		</div>
	);
}
