import React, { useState } from "react";
import FeatureSection from "../components/FeatureSection";
import FooterSection from "../components/FooterSection";
import HomeSection from "../components/HomeSection";
import HowItWorkSection from "../components/HowItWorkSection";
// import TestimonialSection from "../components/TestimonialSection";
import Hamburger from "../components/Hamburger";
import Navigation from "../components/Navigation";
import NavBar from "../components/NavBar";
import { useScrollPosition } from "../customHooks/useScroll";
import { useRef } from "react";

export default function Home() {
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [isToggle, setIsToggle] = useState(false);
	const visible = useScrollPosition();

	const section1Ref = useRef(null);
	const section2Ref = useRef(null);
	const section3Ref = useRef(null);
	// const section4Ref = useRef(null);
	const section5Ref = useRef(null);

	return (
		<div id="home">
			<NavBar
				refs={[section1Ref, section2Ref, section3Ref, section5Ref]}
				visible={visible}
			/>
			<Hamburger isToggle={isToggle} setIsToggle={setIsToggle} />

			{isToggle ? (
				<Navigation
					setIsToggle={setIsToggle}
					refs={[section1Ref, section2Ref, section3Ref, section5Ref]}
				/>
			) : null}
			<HomeSection refs={section1Ref} setIsModalOpen={setIsModalOpen} />
			<HowItWorkSection
				refs={section2Ref}
				isModalOpen={isModalOpen}
				setIsModalOpen={setIsModalOpen}
			/>
			<FeatureSection refs={section3Ref} />
			{/* Change .section5 class when the testimonial section uncomment */}
			{/* <TestimonialSection ref={section4Ref} /> */}
			<FooterSection refs={section5Ref} />
		</div>
	);
}
