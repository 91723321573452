import React from "react";
import logo from "../images/logo-white.svg";
const Hamburger = ({ setIsToggle, isToggle }) => {
	const handleHamburger = () => {
		setIsToggle(!isToggle);
		// console.log(isToggle);
	};

	return (
		<div class="hamburger">
			{!isToggle && (
				<div className="logo">
					<img src={logo} alt="" width="60px" />
				</div>
			)}
			<div class="menu cross menu--1">
				<label>
					<input type="checkbox" onChange={handleHamburger} />
					<svg viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
						<circle cx="50" cy="50" r="30" />
						<path class="line--1" d="M0 40h62c13 0 6 28-4 18L35 35" />
						<path class="line--2" d="M0 50h70" />
						<path class="line--3" d="M0 60h62c13 0 6-28-4-18L35 65" />
					</svg>
				</label>
			</div>
		</div>
	);
};

export default Hamburger;
