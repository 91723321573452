import React from "react";
import { IoMdPlay, IoIosOpen } from "react-icons/io";
export default function HomeSection({ setIsModalOpen, refs }) {
	return (
		<div ref={refs} className="section1">
			<div className="main-content-con">
				<div className="iphone">
					<div></div>
				</div>
				<div className="content-container">
					<div className="title">Introducing Entrepedia</div>
					<div className="content">
						The Entrepredia app and platform gives children an experience and
						journey of building their own venture. Through a fun gamified
						system, it allows children to understand the tradeoffs that a
						businessman has to face and fundamentals of running a successful
						startup.
					</div>
					<div className="icons">
						{/* <button className="download_btn">
							Download Now
							<span>
								<FiDownload />
							</span>
						</button> */}
						<a
							href="https://app.entrepedia.in"
							target="_blank"
							rel="noopener noreferrer">
							<button className="download_btn">
								Try Now
								<span>
									<IoIosOpen />
								</span>
							</button>
						</a>
						<div onClick={() => setIsModalOpen(true)} className="play">
							<span>Watch Demo</span>
							<span>
								<IoMdPlay />
							</span>
						</div>
					</div>
				</div>
			</div>
			{/* <div className="arrow-container">
				<div className="arrow-down">
					<IoIosArrowDown />
				</div>
			</div> */}
		</div>
	);
}
